import React, {useState, useEffect} from 'react';
import './Hero.css'
import { Image } from '../core'
import sixteennine from '../../assets/images/169-placeholder.png';
import { Link } from 'react-router-dom'


export const Hero = ({slides, reverse}) => {

  const [ activeSlide, setActiveSlide] = useState(0)
  const [ hasLoaded, setHasLoaded] = useState(false);
  const [ isTransitioning, setIsTransitioning ] = useState(false);
  const [ preventAutoplay, setPreventAutoplay ] = useState(false);

  useEffect(() => {
    setIsTransitioning(false);
      const interval = setInterval(() => {
        if(!preventAutoplay) {
          handleSlide(activeSlide == slides.length - 1 ? 0 : activeSlide + 1)
        }
      }, 7000);
      return () => clearInterval(interval);
  }, [activeSlide]);

  const handleSlide = (i, isClicked) => {
    if(isClicked) {
      setPreventAutoplay(true)
    }
    if(slides.length > 1) {
      setIsTransitioning(true)
      setTimeout(function () {
        setActiveSlide(i)
      }, 350);
    }
  }

  const handleOnLoad = () => {
    setHasLoaded(true)
  }

  return (
    <>
    { slides && slides.map((slide, i) => {
      return (
        <>
        { activeSlide == i &&
        <div key={i} className={'w-full bg-charcoal pb-8 sm:mb-8 relative'}>
          <div className={`${isTransitioning ? activeSlide == i ? 'opacity-0' : 'opacity-100' : 'opacity-100'} hero-slide transition-all duration-1000 hero max-w-screen-lg mx-auto flex ${reverse ? 'flex-row-reverse' : 'flex-row'} items-center`}>
            <div className={`hidden md:block w-6/12 pl-8 lg:pl-0 pr-8 ${reverse ? 'slide-left' : 'slide-right'}`}>
              <h2  className={'text-white sm:text-xl md:text-2xl lg:text-4xl font-extrabold font-primary md:pb-4 lg:pb-8'}>
                {slide.title}
              </h2>
              <p className={'text-white md:text-md lg:text-lg font-body font-light pb-8'} dangerouslySetInnerHTML={{__html: slide.content}}>
              </p>
              {slide.link == 'sign-up-form-slider-anchor-only' ?
              <a href={`#sign-up-form`}>
              {slide.cta &&
                <button className={'bg-magenta md:text-md uppercase lg:text-lg text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                  {slide.cta.length > 0 ? slide.cta : 'Read More'}
                </button>
              }
              </a>
              :
              <Link to={`/${slide.link}`}>
              {slide.cta &&
                <button className={'bg-magenta md:text-md uppercase lg:text-lg text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                  {slide.cta.length > 0 ? slide.cta : 'Read More'}
                </button>
              }
              </Link>
            }

            </div>
            <div className={`w-full md:w-6/12
              ${reverse ? 'pl-0 pr-0 sm:pl-4 lg:pr-8 lg:pl-0' : 'pl-0 pr-0 sm:pr-4 md:pr-8 lg:pr-0 lg:pl-8'}
            ${reverse ? 'sm:slide-right' : 'sm:slide-left'}
              ${hasLoaded ? '' : 'min-h-[360px]' }`}>
              <Image onLoad={() => handleOnLoad(true)} src={slide.image}/>
              <h2 className={'md:hidden w-full px-2 pt-8 w-full text-center text-2xl sm:text-4xl text-white font-bold font-primary'}>
                {slide.title}
              </h2>
              <p className={'md:hidden text-white text-center font-body text-lg sm:text-xl p-2'} dangerouslySetInnerHTML={{__html: slide.content}}>
              </p>
              <div className="md:hidden flex justify-center">
                <Link to={`/${slide.link}`}>
                {slide.cta &&
                  <button className={'mx-auto w-full max-w-[320px] uppercase bg-magenta mt-2 text-white font-bold font-body rounded-3xl px-8 py-2'}>
                    {slide.cta.length > 0 ? slide.cta : 'Read More'}
                  </button>
                }
                </Link>
              </div>
            </div>
          </div>
        </div>
        }
        </>
      )
    })
    }

    { slides.length > 1 &&
      <div className={'slider-dots fade-in w-full flex justify-center gap-4 mb-8'}>
        {slides.map((slide, i) => (
          <p key={i} onClick={() => handleSlide(i, true)} className={` ${i == activeSlide ? 'bg-white' : 'bg-teal'} slider-dot cursor-pointer w-[12px] h-[12px] flex justify-center items-center text-teal rounded-2xl font-bold`}>&nbsp;</p>
        ))}
      </div>
    }
    </>
  )
}

export default Hero;
