import {useState, useEffect} from 'react'
import { Double, Treble, Card, Hero } from '../components'
import placeholder from '../assets/images/lets-chat-law-event.jpg'
import DOMPurify from 'dompurify';
import { getPage, getPosts } from '../api/api';

export const About = () =>  {

  // const [hasLoaded, setHasLoaded] = useState(null)
  // const [clearTransition, setClearTransition] = useState(null)
  const [about, setAbout] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null)

  const getAboutPageContent = () => {
    getPage(50).then(result => {
      setAbout({
        hero: {
            title: result.acf.hero.title,
            image: result.acf.hero.image.url,
            content: DOMPurify.sanitize(result.acf.hero.content)
        },
        double: {
            image: result.acf.double.image,
            content: DOMPurify.sanitize(result.acf.double.content)
        },
        treble: [
          {
             title: result.acf.triple.left.title,
             content: result.acf.triple.left.content,
             link: result.acf.triple.left.link.post_name,
             button: result.acf.triple.left.button_text,
             external_link: result.acf.triple.left.external_link
           },
           {
              title: result.acf.triple.center.title,
              content: result.acf.triple.center.content,
              link: result.acf.triple.center.link.post_name,
              button: result.acf.triple.center.button_text,
              external_link: result.acf.triple.center.external_link
            },
           {
              title: result.acf.triple.right.title,
              content: result.acf.triple.right.content,
              link: result.acf.triple.right.link.post_name,
              button: result.acf.triple.right.button_text,
              external_link: result.acf.triple.right.external_link
            },
        ]
      })
    })
  }

  const getTeamMembers = (page) => {
    getPosts(page, 6, null, 20).then(result => {
      let arr = []
      result.data.reverse().forEach((post, i) => {
        arr.push({
            // id: post.id,
            title: post.title.rendered,
            categories: post.categories,
            content: DOMPurify.sanitize(post.excerpt.rendered),
            position: post.acf.position,
            slug: post.slug,
            image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : null,
            sticky: post.sticky,
            email: post.acf.email,
            linkedIn: post.acf['linked-in']
        })
      });
      setTeamMembers(arr)
      // setTeamMembers(result.data.map(post => ({
      //   id: post.id,
      //   title: post.title.rendered,
      //   content: post.content.rendered,
      //   position: post.position,
      //   image: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
      // })))
    })
  }

  // const handleOnLoad = () => {
  //   const fadeOut = setTimeout(function () {
  //     setHasLoaded(true)
  //   }, 300);
  //
  //   const hide = setTimeout(function() {
  //     setClearTransition(true)
  //   }, 600)
  // }

  // useEffect(() => {
  //
  // }, [hasLoaded])

  useEffect(() => {
    getAboutPageContent()
    getTeamMembers(1)
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className={'bg-charcoal overflow-x-hidden min-h-[100vh]'}>
    { about &&
      <>
      <Hero
        slides={[{title: about.hero.title, content: about.hero.content, image: about.hero.image}]}
      />
      <Double image={about.double.image}>
        <h3 className={'slide-right p-12 text-white text-xl font-body font-light'} dangerouslySetInnerHTML={{__html: about.double.content}}></h3>
      </Double>
      <Treble content={about.treble}/>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-white text-2xl md:text-4xl px-4 md:px-0 py-8 text-center md:text-left mb-0 border-b border-magenta'}>Meet the Founders</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2  pt-8'}>
        {
          teamMembers && teamMembers.filter(m => m.categories.includes(11)).map((teamMember, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : ''} p-4 md:p-0`}>
            <Card image={teamMember.image} title={teamMember.title} email={teamMember.email} linkedin={teamMember.linkedIn} altSubtitle={teamMember.position} content={teamMember.content}/>
            {

              <div className={`absolute ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }

      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-white text-2xl md:text-4xl px-4 md:px-0 py-8 text-center md:text-left mb-0 border-b border-magenta'}>Meet the Events Team</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto grid grid-cols-2 md:grid-cols-3  pt-8'}>
        {
          teamMembers && teamMembers.filter(m => m.categories.includes(10)).map((teamMember, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : ''} p-4 md:p-0`}>
            <Card noPadding image={teamMember.image} title={teamMember.title} altSubtitle={teamMember.position} email={teamMember.email} linkedin={teamMember.linkedIn}  content={teamMember.content}/>
            {

              <div className={`absolute ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }

      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-white text-2xl md:text-4xl px-4 md:px-0 py-8 text-center md:text-left mb-0 border-b border-magenta'}>Meet the Marketing Team</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto grid grid-cols-2 md:grid-cols-3  pt-8'}>
        {
          teamMembers && teamMembers.filter(m => m.categories.includes(15)).map((teamMember, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : ''} p-4 md:p-0`}>
            <Card noPadding image={teamMember.image} title={teamMember.title} altSubtitle={teamMember.position} email={teamMember.email} linkedin={teamMember.linkedIn}  content={teamMember.content}/>
            {

              <div className={`absolute ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }

      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-white text-2xl md:text-4xl px-4 md:px-0 py-8 text-center md:text-left mb-0 border-b border-magenta'}>Meet the Podcast Team</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto grid grid-cols-2 md:grid-cols-3  pt-8'}>
        {
          teamMembers && teamMembers.filter(m => m.categories.includes(16)).map((teamMember, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : ''} p-4 md:p-0`}>
            <Card noPadding image={teamMember.image} title={teamMember.title} altSubtitle={teamMember.position} email={teamMember.email} linkedin={teamMember.linkedIn}  content={teamMember.content}/>
            {

              <div className={`absolute ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }

      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-white text-2xl md:text-4xl px-4 md:px-0 py-8 text-center md:text-left mb-0 border-b border-magenta'}>Meet the Website & Newsletter Team</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto grid grid-cols-2 md:grid-cols-3  pt-8'}>
        {
          teamMembers && teamMembers.filter(m => m.categories.includes(9)).map((teamMember, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : ''} p-4 md:p-0`}>
            <Card noPadding image={teamMember.image} title={teamMember.title} altSubtitle={teamMember.position} email={teamMember.email} linkedin={teamMember.linkedIn}  content={teamMember.content}/>
            {

              <div className={`absolute ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }

      </div>
      </>
      }
    </div>
  );
}

export default About;
