import {useState, useEffect} from 'react'
import placeholder from '../assets/images/43-placeholder.png';
import {Hero, Filter, Card, Treble} from '../components'
import DOMPurify from 'dompurify';
import {format} from 'date-fns'
import { getPage, getPosts, getSpotifyShows } from '../api/api';

export const Podcasts = ({handleAudio}) =>  {

  const PAGE_ID = 56;
  const POST_ID = 4;

  const [podcastsPage, setPodcastsPage] = useState(null)
  const [podcastPosts, setPodcastPosts] = useState(null)

  const getPodcastShows = () => {
    getSpotifyShows().then(result => {
      console.log(result);
    })
  }

  const getPodcastPosts = (page) => {
    getPosts(page, POST_ID).then(result => {
      console.log(result);
      setPodcastPosts(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.excerpt.rendered,
        position: post.position,
        date: post.date,
        tags: post.tags,
        image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : null,
        slug: post.slug,
        audio: post.acf.podcast_spotify
      })))
    })
  }

  const getPodcastsPageContent = () => {
    getPage(PAGE_ID).then(result => {
      setPodcastsPage({
        hero: {
            title: DOMPurify.sanitize(result.acf.hero.title),
            image: result.acf.hero.image.url,
            content: DOMPurify.sanitize(result.acf.hero.content)
        },
        slider: [
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_1.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_1.excerpt),
            image: result.acf.slider.slider_1.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_1.button_text),
            link: result.acf.slider.slider_1.internal_post.post_name,
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_2.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_2.excerpt),
            image: result.acf.slider.slider_2.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_2.button_text),
            link: result.acf.slider.slider_2.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_3.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_3.excerpt),
            image: result.acf.slider.slider_3.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_3.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_4.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_4.excerpt),
            image: result.acf.slider.slider_4.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_4.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          }
        ],
        treble: [
         {
            title: result.acf.triple.left.title,
            content: result.acf.triple.left.content,
            link: result.acf.triple.left.link.post_name,
            button: result.acf.triple.left.button_text,
            external_link: result.acf.triple.left.external_link
          },
          {
             title: result.acf.triple.center.title,
             content: result.acf.triple.center.content,
             link: result.acf.triple.center.link.post_name,
             button: result.acf.triple.center.button_text,
             external_link: result.acf.triple.center.external_link
           },
          {
             title: result.acf.triple.right.title,
             content: result.acf.triple.right.content,
             link: result.acf.triple.right.link.post_name,
             button: result.acf.triple.right.button_text,
             external_link: result.acf.triple.right.external_link
           },
        ]
      })
    })
  }



  useEffect(() => {
    // getPodcastShows()
    getPodcastPosts(1)
    getPodcastsPageContent()
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className={'bg-charcoal min-h-[100vh]'}>
    <div className={'min-h-[90vh]'}>
      { podcastsPage &&
        <Hero
          slides={podcastsPage.slider.filter(s => s.title)}
        />
      }
      { podcastsPage &&
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <Treble content={podcastsPage.treble} />
      </div>
      }
      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-2xl md:text-4xl px-4 md:px-0 text-white text-4xl py-8 pb-8 text-left mb-16 border-b border-magenta'}>Latest Episodes</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto px-8 slide-bottom'}>
      { podcastPosts &&
        podcastPosts.map((post, i) => {
          return (
            <div key={i} className={'py-8 md:py-0 md:pb-4'}>
              <Card image={post.image} audio={post.audio} link={post.slug} handleAudio={handleAudio} subtitle={format(new Date(post.date), 'dd/MM/yyyy')} pills={[]} title={post.title} content={post.content}/>
            </div>
          )
        })
      }

      </div>
    </div>
  );
}

export default Podcasts;
