
import React, {useState, useEffect} from 'react';
import {getPage} from '../api/api'


export const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null)
  const getPrivacyPolicyContent = () => {
    getPage(645).then(result => {
      setPrivacyPolicy({
        title: result.title.rendered,
        content: result.content.rendered
      })
    })
  }
  useEffect(() => {
    getPrivacyPolicyContent()
  }, [])
  return (
    <>
      {
        privacyPolicy &&
        <div className={'p-8 min-h-[100vh] max-w-screen-lg mx-auto text-white font-secondary'}>
          <h1 className={'text-xl pb-4'}>Privacy Policy</h1>
          <p dangerouslySetInnerHTML={{__html: privacyPolicy.content}}></p>
        </div>
      }
    </>
  )
}

export default PrivacyPolicy
