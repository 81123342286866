import React from 'react';
import './Loading.css'

const Loading = () => {
    return (
    <>
        <div className={'min-h-[75vh] w-full flex items-center justify-center'}>
            <div class="lds-ring fade-in"><div></div><div></div><div></div><div></div></div>
        </div>
    </>
    )
}

export default Loading