import React from 'react';
import './Double.css'
import { Image } from '../core';


export const Double = ({reverse, image, children, stats}) => {
  const STATS = [
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
    {
      number: 100,
      label: 'Students served'
    },
  ]
  return (
    <>
    <div className={'w-full border-t border-b border-teal'}>
      <div className={`relative max-w-screen-lg mx-auto block md:flex fade-in ${reverse ? 'flex-row' : 'flex-row-reverse'}`}>
        <div className={'w-full flex items-center border-r border-teal'}>
          {children}
        </div>
        <div className={`w-full fade-in ${reverse ? 'fade-in' : 'slide-right'}`}>
          <Image src={image ? image : null}/>
        </div>
        {/*
          <div className={'absolute right-[100%] w-[240px] h-[100%] flex items-center'}>
          <div >
            {
              STATS.filter((s, i) => i % 2 == 0).map((stat) => {
                  return (
                    <>
                    <p className={'text-coral text-4xl font-primary mt-4'}>{stat.number}</p>
                    <p className={'text-white font-secondary mb-4'}>{stat.label}</p>
                    </>
                  )
              })
            }
            </div>
          </div>
        */}
        {/*
          <div className={'absolute left-[100%] w-[240px] h-[100%] flex items-center'}>
          <div className={'w-full'}>
            {
              STATS.filter((s, i) => i % 2 == 0).map((stat) => {
                  return (
                    <>
                    <p className={'text-coral text-right w-full text-4xl font-primary mt-4'}>{stat.number}</p>
                    <p className={'text-white text-right w-full font-secondary mb-4'}>{stat.label}</p>
                    </>
                  )
              })
            }
            </div>
          </div>
      */  }
      </div>
    </div>
    </>
  )
}

export default Double;
