import React, {useState} from 'react';


export const Player = ({audio, clearAudio}) => {
  const [active, setActive] = useState(true)
  return (
    <>
      <div className={`w-full fixed bottom-0 z-50 ${active ? '' : 'translate-y-[150px]'}`}>
        { audio &&
          <button onClick={() => active ? setActive(false) : setActive(true)} className={'fixed bottom-[160px] px-8 py-1 rounded-xl bg-magenta shadow-md text-white text-sm right-[74px] md:right-[120px]'}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`transition-all-300 w-6 h-6 ${active ? 'rotate-0' : 'rotate-180'}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
</svg>
</button>
}{audio &&
            <button onClick={clearAudio} className={'fixed bottom-[160px] px-4 py-1 rounded-xl bg-magenta shadow-md text-sm text-white right-2 md:right-8'}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
    </button>
}
        <p dangerouslySetInnerHTML={{__html: audio}}></p>
      </div>
    </>
  )
}

export default Player;
