import React, {useState, useEffect} from 'react';
import { Card, Image } from '../../components';
import {getPosts} from '../../api/api'
import placeholder from '../../assets/images/lets-chat-law-event.jpg'
import {Link} from 'react-router-dom';
import DOMPurify from 'dompurify';


import {format} from 'date-fns'


export const HomeBody = ({podcastImage, handleAudio}) => {

    const RESOURCES_ID = 5;
    const EVENTS_ID = 3;
    const PODCASTS_ID = 4;
    
    const [homePageArticles, setHomePageArticles] = useState(null)
    const [homePagePodcasts, setHomePagePodcasts] = useState(null)
    const [homePageEvents, setHomePageEvents] = useState(null)
    
  const getHomePageArticles = async (page) => {
    getPosts(page, RESOURCES_ID).then(result => {
      console.log(result)
      setHomePageArticles(result.data.map(post => ({
        id: post.id,
        title: DOMPurify.sanitize(post.title.rendered),
        excerpt: DOMPurify.sanitize(post.excerpt.rendered),
        slug: post.slug
      })))
    })
  }

  const getHomePagePodcasts = async (page) => {
    getPosts(page, PODCASTS_ID).then(result => {
      setHomePagePodcasts(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        excerpt: post.excerpt.rendered,
        date: post.date,
        audio: post.acf.podcast_spotify,
        image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : placeholder,
        slug: post.slug
      })))
    })
  }

  const getHomePageEvents = async (page) => {
    getPosts(page, EVENTS_ID).then(result => {
      console.log(result);
      setHomePageEvents(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.excerpt.rendered,
        venue: post.acf.event_details.event_venue,
        price: post.acf.event_details.event_price,
        date: post.acf.event_details.event_date,
        starting_time: post.acf.event_details.event_date_time_group.event_starting_time,
        finishing_time: post.acf.event_details.event_date_time_group.event_finishing_time,
        eventbrite: post.acf.event_details.eventbrite_link,
        image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : placeholder,
        slug: post.slug
      })))
    })
  }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const [articles, podcasts, events] = await Promise.all([
              getHomePageArticles(1),
              getHomePagePodcasts(1),
              getHomePageEvents(1)
            ]);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, []);

    return (
        <div>
            <div className={'max-w-screen-lg mx-auto mt-4 lg:mt-32   border-b border-magenta flex justify-between'}>
                <h2 className={'font-primary text-white text-3xl lg:text-4xl py-8 px-4 lg:px-0 text-center lg:text-left '}>Latest Resources</h2>
                <Link to={'/resource-hub'}><button className={'mx-auto mr-4 lg:mr-0 md:inline-block hidden uppercase bg-magenta mt-8 text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                View All
                </button></Link>
            </div>
            <div className={'max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2'}>
            {
                homePageArticles && homePageArticles.slice(0,6).map((resource) => (
                <div className={'px-4 mt-4 md:mt-0 md:px-0 md:py-2'}>
                <Card cta={'Read More'} link={resource.slug} title={resource.title} content={resource.excerpt}/>
                </div>
                ))
            }
            </div>
                <div className={'max-w-screen-lg mx-auto flex justify-center mb-20 px-4'}>
                <Link to={'/resource-hub'}><button className={'mx-auto uppercase md:hidden bg-charcoal border border-magenta mt-8 text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                View All Resources
                </button></Link>
            </div>
            <div className={'max-w-screen-lg mx-auto mt-32 mb-12 w-full  border-b border-magenta flex justify-between'}>
                <h2 className={'font-primary  text-white text-4xl py-8 px-4 lg:px-0 text-center md:text-left md:w-auto w-full '}>Podcast</h2>
                <Link to={'/podcasts'}><button className={'mx-auto mr-4 lg:mr-0  md:inline-block hidden bg-magenta mt-8 uppercase text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                View All
                </button></Link>
            </div>
            <div className={'max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 mb-0 md:mb-16'}>
                {podcastImage && <Image src={podcastImage}/>}
                { homePagePodcasts && homePagePodcasts.slice(0,1).map((podcast) => (
                <div className={'p-4 md:p-0 border border-teal max-h-[384px]'}>
                <Card audio={podcast.audio} link={podcast.slug} handleAudio={handleAudio} subtitle={format(new Date(podcast.date), 'dd/MM/yyyy')} title={podcast.title} content={podcast.excerpt}/>
                </div>
                ))
                }
            </div>
            <div className={'max-w-screen-lg mx-auto flex justify-center mb-20'}>
            <Link to={'/podcasts'}><button className={'mx-auto uppercase md:hidden bg-charcoal border border-magenta mt-8 text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                View All Podcasts
            </button></Link>
            </div>
            <div className={'max-w-screen-lg mx-auto mt-32   border-b border-magenta flex justify-between'}>
                <h2 className={'font-primary text-white text-4xl py-8 px-4 lg:px-0 text-center md:text-left md:w-auto w-full'}>Events</h2>
                <Link to={'/events'}><button className={'hidden uppercase mr-4 lg:mr-0 md:inline-block uppercase mx-auto bg-magenta mt-8 text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                View All
                </button></Link>
            </div>
            <div className={'bg-charcoal'}>
                { homePageEvents &&
                <div className={'fade-in slide-bottom max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 pt-8 slide-bottom'}>
                {
                    homePageEvents.slice(0,2).map((eventPost, i) => (
                    <div className={`py-8 md:py-0 relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : 'bg-charcoal'} p-4 md:p-0`}>
                    <Card image={eventPost.image} title={eventPost.title} pills={[eventPost.date ? eventPost.date : eventPost.starting_time, eventPost.venue, eventPost.price]} content={eventPost.content} cta={'Find Out More'} link={eventPost.slug}/>
                    {

                        <div className={`absolute fade-in ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-mulberry' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
                    }
                    </div>
                    ))
                }
                <div className={'max-w-screen-lg mx-auto flex justify-center mb-20'}>
                <Link to={'/events'}><button className={'mx-auto md:hidden uppercase bg-charcoal border border-magenta mt-8 text-white font-secondary font-bold rounded-3xl px-8 py-2'}>
                    View All Events
                </button></Link>
                </div>
                </div>
            }
            </div>
        </div>
    )
}

export default HomeBody