import {useState, useEffect} from 'react'
import { Double, Treble, Card, Pagination, Hero } from '../components'
import placeholder from '../assets/images/lets-chat-law-event.jpg'
import DOMPurify from 'dompurify';
import {getPage, getPosts} from '../api/api'
import {Link} from 'react-router-dom';

export const Events = () =>  {

  const PAGE_ID = 52;
  const POST_ID = 3;

  const [eventsPage, setEventsPage] = useState(null)
  const [eventPosts, setEventPosts] = useState(null)
  const [upcomingPosts, setUpcomingPosts] = useState(null)
  const [upcomingPostsLoaded, setUpcomingPostsLoaded] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const getEventsPageContent = () => {
    getPage(PAGE_ID).then(result => {
      setEventsPage({
        hero: {
            title: result.acf.hero.title,
            image: result.acf.hero.image.url,
            content: DOMPurify.sanitize(result.acf.hero.content)
        },
        slider: [
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_1.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_1.excerpt),
            image: result.acf.slider.slider_1.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_1.button_text),
            link: result.acf.slider.slider_1.internal_post.post_name,
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_2.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_2.excerpt),
            image: result.acf.slider.slider_2.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_2.button_text),
            link: result.acf.slider.slider_2.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_3.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_3.excerpt),
            image: result.acf.slider.slider_3.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_3.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_4.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_4.excerpt),
            image: result.acf.slider.slider_4.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_4.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          }
        ],
        treble: [
          {
             title: result.acf.triple.left.title,
             content: result.acf.triple.left.content,
             link: result.acf.triple.left.link.post_name,
             button: result.acf.triple.left.button_text,
             external_link: result.acf.triple.left.external_link
           },
           {
              title: result.acf.triple.center.title,
              content: result.acf.triple.center.content,
              link: result.acf.triple.center.link.post_name,
              button: result.acf.triple.center.button_text,
              external_link: result.acf.triple.center.external_link
            },
           {
              title: result.acf.triple.right.title,
              content: result.acf.triple.right.content,
              link: result.acf.triple.right.link.post_name,
              button: result.acf.triple.right.button_text,
              external_link: result.acf.triple.right.external_link
            },
        ]
      })
    })
  }

  const getEvents = (page) => {
    getPosts(page, POST_ID).then(result => {
      if(!upcomingPostsLoaded) {
      setUpcomingPosts(result.data.filter(p => new Date(p.acf.event_details.event_date_time_group.event_starting_time) > new Date()).map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.excerpt.rendered,
        venue: post.acf.event_details.event_venue,
        price: post.acf.event_details.event_price,
        date: post.acf.event_details.event_date,
        starting_time: post.acf.event_details.event_date_time_group.event_starting_time,
        eventbrite: post.acf.event_details.eventbrite_link,
        image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : placeholder,
        slug: post.slug
      })))
      setUpcomingPostsLoaded(true)
      }
      setEventPosts(result.data.filter(p => new Date(p.acf.event_details.event_date_time_group.event_starting_time) < new Date()).map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.excerpt.rendered,
        venue: post.acf.event_details.event_venue,
        price: post.acf.event_details.event_price,
        date: post.acf.event_details.event_date,
        starting_time: post.acf.event_details.event_date_time_group.event_starting_time,
        finishing_time: post.acf.event_details.event_date_time_group.event_finishing_time,
        eventbrite: post.acf.event_details.eventbrite_link,
        image: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : placeholder,
        slug: post.slug
      })))
      setTotalPages(result.headers['x-wp-totalpages'])
    })
  }

  useEffect(() => {
    getEventsPageContent()
    getEvents(1)
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    getEvents(currentPage)
  }, [currentPage])

  useEffect(() => {
    console.log(eventsPage)
  }, [eventsPage])

  return (
    <div className={'bg-charcoal min-h-[100vh]'}>
    <div className={'min-h-[90vh]'}>
      { eventsPage &&
        <Hero
          slides={eventsPage.slider.filter(s => s.title)}
        />
      }
      { eventsPage &&
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <Treble content={eventsPage.treble} />
      </div>
      }
      </div>
      <div className={'max-w-screen-lg mx-auto mt-16'}>
        <h2 className={'font-primary mt-8 text-2xl md:text-4xl px-4 md:px-0 text-white border-b border-b-magenta text-4xl py-8 text-center md:text-left mb-16 border-b border-coral'}>
       Upcoming Events
        </h2>
      </div>
      { eventPosts &&
        <>
        <div className={'fade-in slide-bottom max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 pt-8 slide-bottom'}>
        {
          eventPosts && upcomingPosts.map((eventPost, i) => (
            <div className={`relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : 'bg-charcoal'} p-4 md:p-0`}>
            <Card image={eventPost.image} link={eventPost.slug}  title={eventPost.title} pills={[eventPost.starting_time, eventPost.venue, eventPost.price]} content={eventPost.content} cta={'Find Out More'}/>
            {

              <div className={`absolute fade-in ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }
        </div>
        <div className={'max-w-screen-lg mx-auto mt-16'}>
          <h2 className={'font-primary mt-8 text-2xl md:text-4xl px-4 text-white border-b border-b-magenta text-4xl py-8 text-center md:text-left mb-16 border-b border-coral'}>
         Past Events
          </h2>
        </div>
        <div className={'fade-in slide-bottom max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-3 pt-8 slide-bottom'}>
        {
          eventPosts && eventPosts.map((eventPost, i) => (
            <div className={` mb-8 relative ${(i + 1) % 2 == 0 ? 'bg-charcoal relative' : ''} ${ (i + 1) % 4 == 0 ? 'bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'bg-charcoal' : 'bg-charcoal'} p-4 md:p-0`}>
            <Card noPadding image={eventPost.image} link={eventPost.slug}  title={eventPost.title} content={eventPost.content}/>
            {
              <div className={`absolute fade-in ${(i + 1) % 2 == 0 ? 'bg-charcoal' : 'bg-charcoal'} ${(i + 1) % 4 == 1 ? 'right-[0vw] bg-charcoal' : ''} w-[50vw] top-0 ${ (i + 1) % 4 == 0 ? 'right-[50vw] bg-charcoal' : ''} ${ (i + 1) % 4 == 3 ? 'left-[50vw]' : ''} z-index-0 h-[100%] z-0`}></div>
            }
            </div>
          ))
        }
      </div>
      </>
    }

    { totalPages &&
      <div className={'max-w-screen-xl mx-auto w-[100%] py-8 pr-2 md:pr-0'}>
      <Pagination activePage={currentPage} handleActivePage={setCurrentPage} pages={totalPages} />
      <div className={'h-[4rem]'}></div>
      </div>
    }
    </div>
  );
}

export default Events;
