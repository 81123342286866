import {useState, useEffect} from 'react'
import { Hero, Treble, Double, Loading } from '../components';
import HomeBody from '../components/home-subcomponents/HomeBody';
import DOMPurify from 'dompurify';
import {getPage} from '../api/api'

export const Home = ({handleAudio}) =>  {

  const PAGE_ID = 22
  const [homePageContent, setHomePageContent] = useState(null)

  const getHomePageContent = async () => {
    getPage(PAGE_ID).then(result => {
      console.log(result)
      setHomePageContent({
        slider: [
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_1.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_1.excerpt),
            image: result.acf.slider.slider_1.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_1.button_text),
            link: result.acf.slider.slider_1.internal_post.post_name,
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_2.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_2.excerpt),
            image: result.acf.slider.slider_2.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_2.button_text),
            link: result.acf.slider.slider_2.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_3.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_3.excerpt),
            image: result.acf.slider.slider_3.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_3.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          },
          {
            title: DOMPurify.sanitize(result.acf.slider.slider_4.heading),
            content: DOMPurify.sanitize(result.acf.slider.slider_4.excerpt),
            image: result.acf.slider.slider_4.image,
            cta: DOMPurify.sanitize(result.acf.slider.slider_4.button_text),
            link: result.acf.slider.slider_4.internal_post.post_name
          }
        ],
        treble: [
          {
             title: result.acf.triple.left.title,
             content: result.acf.triple.left.content,
             link: result.acf.triple.left.link.post_name,
             button: result.acf.triple.left.button_text,
             external_link: result.acf.triple.left.external_link
           },
           {
              title: result.acf.triple.center.title,
              content: result.acf.triple.center.content,
              link: result.acf.triple.center.link.post_name,
              button: result.acf.triple.center.button_text,
              external_link: result.acf.triple.center.external_link
            },
           {
              title: result.acf.triple.right.title,
              content: result.acf.triple.right.content,
              link: result.acf.triple.right.link.post_name,
              button: result.acf.triple.right.button_text,
              external_link: result.acf.triple.right.external_link
            },
        ],
        double: {
          content: DOMPurify.sanitize(result.acf.double.content),
          image: result.acf.double.image
        },
        podcast_image: result.acf.podcast_home_image,
        stats: {
          one: {
            value: result.acf.stats.one.value,
            label: result.acf.stats.one.label
          },
          two: {
            value: result.acf.stats.two.value,
            label: result.acf.stats.two.label
          },
          three: {
            value: result.acf.stats.three.value,
            label: result.acf.stats.three.label
          },
          four: {
            value: result.acf.stats.four.value,
            label: result.acf.stats.four.label
          }
        }
      })
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const homePageContent = await getHomePageContent();
        window.scrollTo(0, 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="main bg-charcoal min-h-[100vh] lg:pt-4 xl:pt-8">
      <div className="min-h-[100vh]">
        { homePageContent ?
          <>
          <Hero
            slides={homePageContent.slider.filter(s => s.title)}
          />

          <Treble content={homePageContent.treble} />
          <div className={'md:pt-16'}>
            <Double image={homePageContent.double.image} >
              <h3 className={'py-12 px-8 md:p-12 text-white text-2xl md:text-2xl font-body font-light leading-10'} dangerouslySetInnerHTML={{__html: homePageContent.double.content}}></h3>
            </Double>
          </div>
          <div className={'max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-4 justify-between  my-4 lg:my-16 lg:mt-36 py-8'}>
          <div className={'flex justify-center border-r border-r-teal my-8'}>
            <h1 className={'font-secondary text-white text-center'}><span className={' text-5xl md:text-6xl font-primary text-white text-center block'}>{homePageContent.stats.one.value}</span>{homePageContent.stats.one.label}</h1>
          </div>
          <div className={'flex justify-center border-r border-r-teal my-8'}>
            <h1 className={'font-secondary text-white text-center'}><span className={'text-5xl md:text-6xl font-primary text-white text-center block'}>{homePageContent.stats.two.value}</span>{homePageContent.stats.two.label}</h1>
          </div>
          <div className={'flex justify-center border-r border-r-teal my-8'}>
            <h1 className={'font-secondary text-white text-center'}><span className={'text-5xl md:text-6xl font-primary text-white text-center block'}>{homePageContent.stats.three.value}</span>{homePageContent.stats.three.label}</h1>
          </div>
          <div className={'flex justify-center my-8'}>
            <h1 className={'font-secondary text-white text-center'}><span className={'text-5xl md:text-6xl font-primary text-white text-center block'}>{homePageContent.stats.four.value}</span>{homePageContent.stats.four.label}</h1>
          </div>

          </div>
          </>
        :
        <Loading /> 
        }
      </div>
      {homePageContent ? <HomeBody podcastImage={homePageContent.podcast_image} handleAudio={handleAudio} /> : <Loading />}
    </div>
  );
}

export default Home;
