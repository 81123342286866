export const NavigationIcon = ({type}) => {
  return (
    <>
      { type == 'linkedin' &&
              <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-7 h-7 stroke-teal-mid hover:stroke-white antialiased"><title data-testid="svgTitle" id="title_0.8729917650515822">linkedin</title><path d="M4.425,1.671A2.738,2.738,0,0,0,1.5,4.4,2.71,2.71,0,0,0,4.369,7.128H4.4a2.734,2.734,0,1,0,.028-5.457ZM4.4,6.128H4.369a1.736,1.736,0,1,1,.056-3.457A1.737,1.737,0,1,1,4.4,6.128Z"></path><path d="M6.541,8.431H2.253a.5.5,0,0,0-.5.5v12.9a.5.5,0,0,0,.5.5H6.541a.5.5,0,0,0,.5-.5V8.931A.5.5,0,0,0,6.541,8.431Zm-.5,12.9H2.753V9.431H6.041Z"></path><path d="M17.064,8.128A4.691,4.691,0,0,0,13.7,9.362V8.931a.5.5,0,0,0-.5-.5H8.914a.5.5,0,0,0-.5.523c.053,1.183,0,12.756,0,12.873a.5.5,0,0,0,.5.5H13.2a.5.5,0,0,0,.5-.5v-7.2a2.749,2.749,0,0,1,.1-.86,1.869,1.869,0,0,1,1.737-1.254c.413,0,1.671,0,1.671,2.417v6.9a.5.5,0,0,0,.5.5H22a.5.5,0,0,0,.5-.5v-7.4C22.5,10.485,20.467,8.128,17.064,8.128Zm4.436,13.2H18.213v-6.4c0-2.973-1.673-3.417-2.671-3.417a2.83,2.83,0,0,0-2.664,1.878,3.253,3.253,0,0,0-.177,1.236v6.7H9.416c.009-2.058.04-9.654.009-11.9H12.7v1.328a.5.5,0,0,0,.92.272,3.769,3.769,0,0,1,3.443-1.9c2.819,0,4.436,1.934,4.436,5.305Z"></path></svg>
      }
      {
        type == 'facebook' &&
            <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-7 h-7 stroke-teal-mid hover:stroke-white antialiased"><title data-testid="svgTitle" id="title_0.7643801773015726">facebook</title><path d="M23,12A11,11,0,1,0,10.279,22.865h0a11.08,11.08,0,0,0,3.436,0h0A10.952,10.952,0,0,0,23,12ZM10.859,21.935v-6.9a.5.5,0,0,0-.5-.5H8.193V12.5h2.166a.5.5,0,0,0,.5-.5V9.686c0-2.278,1.264-3.585,3.459-3.585a15.392,15.392,0,0,1,1.858.137V7.89h-.824l-.019,0a2,2,0,0,0-2.181,1.735,1.8,1.8,0,0,0-.011.4V12a.5.5,0,0,0,.5.5H15.97l-.312,2.035H13.641a.5.5,0,0,0-.5.5v6.9A10.124,10.124,0,0,1,10.859,21.935Zm3.282-.166V15.535h1.946a.5.5,0,0,0,.5-.425l.465-3.035a.5.5,0,0,0-.494-.575H14.141V10.016a1.267,1.267,0,0,1,.308-.821,1.218,1.218,0,0,1,.9-.3h1.324a.5.5,0,0,0,.5-.5V5.806a.5.5,0,0,0-.42-.494A16.661,16.661,0,0,0,14.325,5.1c-2.754,0-4.466,1.757-4.466,4.585V11.5H7.693a.5.5,0,0,0-.5.5v3.035a.5.5,0,0,0,.5.5H9.859v6.234a10,10,0,1,1,4.282,0Z"></path></svg>
      }
      {
        type == 'tiktok' &&
        <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-7 h-7 stroke-teal-mid hover:stroke-white antialiased"><title data-testid="svgTitle" id="title_0.6465016274362871">tiktok</title><path d="M9.37,23.5a7.468,7.468,0,0,1,0-14.936.537.537,0,0,1,.538.5v3.8a.542.542,0,0,1-.5.5,2.671,2.671,0,1,0,2.645,2.669.432.432,0,0,1,0-.05V1a.5.5,0,0,1,.5-.5h3.787a.5.5,0,0,1,.5.5A4.759,4.759,0,0,0,21.59,5.76a.5.5,0,0,1,.5.5L22.1,10a.533.533,0,0,1-.519.515,9.427,9.427,0,0,1-4.741-1.268v6.789A7.476,7.476,0,0,1,9.37,23.5ZM8.908,9.585a6.466,6.466,0,1,0,6.93,6.447V8.326a.5.5,0,0,1,.791-.407A8.441,8.441,0,0,0,21.1,9.5l-.006-2.76A5.761,5.761,0,0,1,15.859,1.5H13.051V16.032a.458.458,0,0,1,0,.053,3.672,3.672,0,1,1-4.14-3.695Z"></path></svg>
      }
      {
        type == 'spotify' &&
        <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-7 h-7 stroke-teal-mid hover:stroke-white antialiased"><title data-testid="svgTitle" id="title_0.8023373686201174">spotify</title><path d="M18,10.561a.494.494,0,0,1-.245-.065,15.2,15.2,0,0,0-10.95-1.55.5.5,0,0,1-.232-.973A16.2,16.2,0,0,1,18.25,9.626a.5.5,0,0,1-.247.935Z"></path><path d="M16.646,13.632a.5.5,0,0,1-.249-.066,12.459,12.459,0,0,0-9.121-1.292.5.5,0,1,1-.237-.971A13.458,13.458,0,0,1,16.9,12.7a.5.5,0,0,1-.25.933Z"></path><path d="M15.312,16.583a.5.5,0,0,1-.251-.068,9.777,9.777,0,0,0-7.295-1.033.5.5,0,0,1-.245-.97,10.768,10.768,0,0,1,8.043,1.139.5.5,0,0,1-.252.932Z"></path><path d="M12,23A11,11,0,1,1,23,12,11.013,11.013,0,0,1,12,23ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"></path></svg>
      }
      {
        type == 'podcasts' &&
        <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-7 h-7 stroke-teal-mid hover:stroke-white antialiased"><title data-testid="svgTitle" id="title_0.8758876961051345">applePodcast</title><path d="M12,23a2.084,2.084,0,0,1-2.125-1.337,13.2,13.2,0,0,1-.345-1.675c-.175-1.132-.322-2.307-.4-3.222a10.951,10.951,0,0,1-.045-2.228,1.78,1.78,0,0,1,.509-1.1,3.294,3.294,0,0,1,2.4-.889,3.3,3.3,0,0,1,2.405.889,1.785,1.785,0,0,1,.509,1.1h0a11.033,11.033,0,0,1-.046,2.228c-.077.911-.222,2.085-.4,3.222a12.929,12.929,0,0,1-.346,1.674A2.079,2.079,0,0,1,12,23Zm0-9.453a2.348,2.348,0,0,0-1.68.577.808.808,0,0,0-.24.522,10.216,10.216,0,0,0,.047,2.035c.076.894.219,2.043.392,3.153a12.062,12.062,0,0,0,.315,1.543c.093.31.489.623,1.166.623s1.073-.313,1.165-.623a11.184,11.184,0,0,0,.316-1.542c.173-1.115.316-2.265.391-3.154a10.107,10.107,0,0,0,.048-2.036.808.808,0,0,0-.24-.521A2.351,2.351,0,0,0,12,13.547Z"></path><path d="M12,12.547a2.806,2.806,0,1,1,2.807-2.806A2.81,2.81,0,0,1,12,12.547Zm0-4.612a1.806,1.806,0,1,0,1.807,1.806A1.809,1.809,0,0,0,12,7.935Z"></path><path d="M16.2,18.959a.5.5,0,0,1-.233-.941,8.5,8.5,0,1,0-7.928,0,.5.5,0,1,1-.467.883,9.5,9.5,0,1,1,8.862,0A.494.494,0,0,1,16.2,18.959Z"></path><path d="M7.58,15.05a.5.5,0,0,1-.368-.162,6.5,6.5,0,1,1,9.572,0,.5.5,0,0,1-.736-.676,5.5,5.5,0,1,0-8.1,0,.5.5,0,0,1-.368.838Z"></path></svg>
      }
      {
        type == 'instagram' &&
        <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" className="w-10 h-10 stroke-teal-mid hover:stroke-white antialiased"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/> </svg>
      }
    </>
  )
}

export default NavigationIcon
