import React, {useState, useEffect} from 'react';
import {useHref} from 'react-router-dom'
import {format} from 'date-fns'
import { getPostBySlug } from '../api/api';
import { Image } from '../components/index'
import {Helmet} from 'react-helmet'

export const Single = () => {
  const history = useHref()
  const [post, setPost] = useState(null)
  useEffect(() => {
      const slug = history.split('/').pop()
      console.log(slug)
      getPostBySlug(slug).then(result => {
        console.log(result)
        setPost({
            id: result[0].id,
            date: result[0].date,
            monthYear: format(new Date(result[0].date), "yy-MM"),
            title: result[0].title.rendered,
            excerpt: result[0].excerpt.rendered,
            content: result[0].content.rendered,
            // image: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
            slug: result[0].slug,
            audio: result[0].acf.podcast_spotify
        })
        Helmet.addTag({ name: 'title', content: result[0].title.rendered })
        Helmet.addTag({ name: 'description', content: result[0].excerpt.rendered })
        Helmet.addTag({ name: 'og:title', content: result[0].title.rendered })
        Helmet.addTag({ name: 'og:description', content: result[0].excerpt.rendered })
        // Helmet.addTag({ name: 'og:image', content: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url})
      })
      window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className={'main min-h-[100vh] bg-charcoal pb-16'}>
      { post &&
        <div className={'max-w-screen-lg mx-auto p-4'}>
          {/*<Image src={post.banner} />*/}
          <h1 className={'font-primary text-magenta text-2xl md:text-4xl md:pt-2 pb-2 mt-0 md:mt-8'} dangerouslySetInnerHTML={{__html: post?.title}}></h1>
          {post.audio && <p dangerouslySetInnerHTML={{__html: post.audio}}></p>}
          <h2 className={'font-secondary text-white md:text-2xl font-bold text-2xl pt-2 pb-4'} dangerouslySetInnerHTML={{__html: post?.excerpt }}></h2>
          <div className={'blog-content font-body text-white text-lg py-4 leading-10'} dangerouslySetInnerHTML={{__html: post.content }}></div>
        </div>
      }
      </div>
    </>
  )
}

export default Single;
