import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';
import { Navigation, Hero, Treble, Double, Footer, Player } from './components';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import {Home, About, ResourceHub, Events, Podcasts, Single, CookiePolicy, PrivacyPolicy } from './pages';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

const loadGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-LJ2YTVYFPJ';
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-LJ2YTVYFPJ');
  };
};

const CookieBanner = () => {
  useEffect(() => {
    if (getCookieConsentValue('myCookieConsent') === 'true') {
      loadGoogleAnalytics();
    }
  }, []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Yes Please"
      cookieName="myCookieConsent"
      enableDeclineButton={true}
      containerClasses={'block'}
      containerStyle={{ display: 'block' }}
      buttonWrapperClasses={"sm:translate-y-10 xl:-translate-x-8 md:max-w-screen-md md:mx-auto"}
      style={{ background: '#333', fontFamily: 'Saira', borderTop: '1px solid #71adb4' }}
      flipButtons
      declineButtonStyle={{ borderRadius: '35px', color: '#fff', background: '#0e5259', fontSize: '1rem', fontWeight: '700', fontFamily: 'Saira', padding: '0.5rem 1.25rem' }}
      declineButtonText={'No, Thank You'}
      buttonStyle={{ color: '#fff', borderRadius: '35px', fontSize: '1rem', fontWeight: '700', fontFamily: 'Saira', background: '#0e5259', padding: '0.5rem 1.25rem' }}
      onAccept={() => {
        loadGoogleAnalytics();
      }}
    >
      <div className={'sm:px-8 mx-auto md:pb-4 font-bold'}>
        <h1 className={'text-xl pb-4'}>Help us out by accepting cookies!</h1>
        Our cookies enable us to review analytics on the site, and help us understand what is popular to direct how we produce content in future. Click to see our <Link to={'/cookie-policy'}><span className={'underline'}>cookie policy</span></Link> and <Link to={'privacy-policy'}><span className={'underline'}>privacy policy</span></Link>.
      </div>
    </CookieConsent>
  );
};


function App() {

  const [playerAudio, setPlayerAudio] = useState(null)
  const [routeTransition, setRouteTransition] = useState(null);

  return (
    <>
    <div className={`main bg-charcoal overflow-x-hidden `}>
    <Router>
      {/*showMobileHeaderMenu && <Navigation />*/}
      <Navigation handleRouteTransition={setRouteTransition}/>
      <div className={`transition duration-400 ${routeTransition ? 'opacity-0' : 'opacity-100'}`}>
      <Routes>
        <Route path={'/'} element={<Home handleAudio={setPlayerAudio} />} />
        <Route path={'/resource-hub'} element={<ResourceHub />} />
        <Route path={'/events'} element={<Events />} />
        <Route path={'/podcasts'} element={<Podcasts handleAudio={setPlayerAudio} />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/:slug'} element={<Single />} />
        <Route path={'/cookie-policy'} element={<CookiePolicy />} />
        <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
      </Routes>
      <CookieBanner />
      </div>
    </Router>
    </div>
    <div className={'w-full bg-teal'}>
    <Footer />
    <Player audio={playerAudio} clearAudio={() => setPlayerAudio(null)}/>
    </div>
    </>
  );
}

export default App;
