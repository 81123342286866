import React from 'react';
import {Link} from 'react-router-dom'

export const TrebleItem = ({content}) => {
  React.useEffect(() => {
    console.log(content)
  }, [content])
  return (
    <>
      <div className={`p-8`}>
        <h3 className={'text-white text-center lg:text-left font-secondary font-bold text-2xl text-md'}>{content.title}</h3>
        <p className={'text-white text-center lg:text-left text-md font-body font-light py-2 pb-4'}>{content.content}</p>
        { content.link && !content.external_link &&
        <div className={'flex justify-center md:justify-start'}>
            <Link to={`/${content.link}`}>
            <button className={'border border-2 border-magenta bg-magenta uppercase text-white font-secondary font-bold text-sm rounded-3xl px-8 py-2'}>
              {content.button}
            </button>
            </Link>
        </div>
        }
        {
          content.external_link &&
          <div className={'flex justify-center md:justify-start'}>
          <a target="_blank" href={`${content.external_link}`}>
          <button className={'border border-2 border-magenta bg-magenta uppercase text-white font-secondary font-bold text-sm rounded-3xl px-8 py-2'}>
            {content.button}
          </button>
          </a>
          </div>
        }
      </div>
    </>
  )
}

export default TrebleItem;
