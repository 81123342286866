import React from 'react';

export const Footer = () => {
  return (
    <div id="sign-up-form" className="bg-teal">
      <div id="fd-form-64a34acc6b32d23b41f6064a"></div>
      <div className={'w-full bg-charcoal p-4 flex justify-end items-center gap-4'}>
        <p className={'text-white'}>Copyright {new Date().getFullYear()} Let's Chat Law CIC</p>
        <a href={'/privacy-policy'}><p className={'text-white font-bold underline'}>Privacy Policy</p></a>
        <a href={'/cookie-policy'}><p className={'text-white font-bold underline'}>Cookie Policy</p></a>
      </div>
    </div>
  )
}

export default Footer;
