export const Filter = ({filters, handleActiveFilter}) =>  {
  return (
    <div>
      {filters.map((filter, i) => {
        return (
          <button key={i} onClick={() => handleActiveFilter(filter)} className={'fade-in border border-teal-light rounded-2xl px-6 my-1 mx-2 text-white font-secondary rounded-3xl text-md px-4 py-1 inline-block'}>
            {filter.name}
          </button>
        )
      })}
    </div>
  );
}

export default Filter;
