import { Image } from '../core'
import { Link } from 'react-router-dom'

export const Card = ({image, subtitle, altSubtitle, email, linkedin, title, pills, content, cta, even, altCta, altLink, link, padding, noPadding, audio, handleAudio}) =>  {
  return (

    <div className={`relative mb-8 md:mb-0 z-10 fade-in ${noPadding ? 'p-4' : padding ? even ? 'pl-8' : 'pr-8' : 'md:p-16'}`}>
      
      { image && !audio && link && <Link to={`/${link}`}><div className={'mb-2'}><Image src={image}/></div></Link>}
      { image && !audio && !link && <div className={'mb-2'}><Image src={image}/></div>}
      
      { subtitle && link && <Link to={`/${link}`}><h4 className={`font-body ${image ? 'text-white' : 'text-coral'}`}>{subtitle}</h4></Link> }
      { subtitle && !link && <h4 className={`font-body ${image ? 'text-white' : 'text-coral'}`}>{subtitle}</h4>}

      { link && <Link to={`/${link}`}><h4 className={`text-md md:text-left text-center font-secondary font-extrabold text-2xl py-2 ${image ? 'text-white' : 'text-white'}`} dangerouslySetInnerHTML={{__html: title}}></h4></Link>}
      { !link && <h4 className={`text-md md:text-left text-center font-secondary font-extrabold text-2xl py-2 ${image ? 'text-white' : 'text-white'}`} dangerouslySetInnerHTML={{__html: title}}></h4>}
      
      {altSubtitle && link && <Link to={`/${link}`}><h4 className={`font-primary md:text-left text-center text-coral -translate-y-2 ${altSubtitle.length > 12 ? 'text-sm' : 'text-lg'}`}>{altSubtitle}</h4></Link>}
      {altSubtitle && !link && <h4 className={`font-primary md:text-left text-center text-coral -translate-y-2 ${altSubtitle.length > 12 ? 'text-sm' : 'text-lg'}`}>{altSubtitle}</h4>}
      <div className={`md:inline-block flex justify-center ${!email && linkedin ? 'mt-[6px]' : ''} `}>
      {
        email &&
        <Link to={`mailto:${email}`}><h4 className={`font-primary text-coral inline-block mr-2 -translate-y-2`}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
        </h4></Link>
      }
      {
        linkedin &&
        <Link to={linkedin}><h4 className={`inline-block font-primary text-teal-light -translate-y-[2px] md:-translate-y-2 `}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" className="w-[17px] h-[17px] -translate-y-[4px]"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg></h4></Link>
      }
      </div>
      {pills &&
        pills.map((pill, i) => {
          return (
            <h6 key={i} className={`${pills.length > 1 ? 'text-white border-teal-mid text-sm font-bold' : 'text-white border-white'} rounded-2xl md:mr-2 md:text-left text-center  md:mb-2 px-3 md:border  font-secondary rounded-3xl px-4 md:py-1 md:inline-block`}>{pill}</h6>
          )
        })
      }
      { link && <Link to={`/${link}`} className={'inline-block'}><p className={' md:text-left text-center text-white py-2 font-body font-light text-lg mb-2'} dangerouslySetInnerHTML={{__html: content}}></p></Link> }
      { !link &&  <p className={' md:text-left text-center text-white py-2 font-body font-light text-lg mb-2'} dangerouslySetInnerHTML={{__html: content}}></p> }
      {/*
        audio &&
      <div className={'relative overflow-hidden mb-4'}>
        <div className={'absolute w-[120px] h-[120px] top-4 left-4 z-50'}><Image src={image} /></div>
        <div className={'absolute font-secondary font-bold top-[40px] left-[180px] text-xl z-50'}><h3 className={'text-white'} dangerouslySetInnerHTML={{__html: title}}></h3></div>
        <button className={'absolute left-[180px] text-lg w-[240px] z-50 py- px-6 border border-white text-white rounded-2xl text-charcoal top-[80px]'}>Listen Now</button>
        <div className={'absolute bg-teal mix-blend-color z-10 opacity-50 w-full h-full'}></div>
          <p class='blur-xl' dangerouslySetInnerHTML={{__html: audio}}></p>
        </div>*/
      }
      <div className={'md:flex'}>
      {
        audio &&
        <div className={'flex md:inline-block justify-center mb-4'}>
        <button onClick={() => handleAudio(audio)} className={'min-w-[180px] rounded-2xl font-extrabold px-6 text-white bg-magenta uppercase border border-magenta mr-0 md:mr-4 font-secondary rounded-3xl px-4 py-2 inline-block'}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 -translate-y-[2px] inline mr-2"><path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" /></svg>Listen Now</button>
        </div>
      }
      {
        cta &&
        <div className={'flex md:inline-block justify-center w-full md:w-auto'}>
          { link ? 
            <Link to={`/${link}`}><button className={'rounded-2xl font-extrabold px-6 text-white bg-magenta uppercase border border-magenta font-secondary rounded-3xl text-sm px-4 py-2 inline-block'}>{cta}</button></Link>
            : <button className={'rounded-2xl font-extrabold px-6 text-white bg-magenta uppercase border border-magenta font-secondary rounded-3xl text-sm px-4 py-2 inline-block'}>{cta}</button>
          }
        </div>
      }
      {
        altCta &&
        <div className={'flex md:inline-block justify-center w-full md:w-auto mt-4 md:mt-0'}>
        <Link to={altCta.link}><button className={`rounded-2xl font-extrabold px-6 text-white bg-teal text-sm border border-teal ${cta ? 'md:ml-2' : ''} font-secondary rounded-3xl px-4 py-1`}>{altCta.label.trim()}</button></Link>
        </div>
      }
      </div>
    </div>
  );
}

export default Card;
