import React, {useState, useEffect, useRef } from 'react'
import placeholder from '../assets/images/43-placeholder.png';
import {Hero, Filter, Card} from '../components'
import DOMPurify from 'dompurify';
import { getPage, getPosts, getCategoriesByParent } from '../api/api';

export const ResourceHub = () =>  {

  const [resourcesHubPage, setResourcesHubPage] = useState(null)
  const [resources, setResources] = useState(null)
  const [filterCategories, setFilterCategories] = useState(null)
  const [filterSubcategories, setFilterSubcategories] = useState(null)
  const [activeFilter, setActiveFilter] = useState(null)

  const latestRef = useRef(null)

  const PAGE_ID = 54;
  const POST_ID = 5;

  const getResourcesHubPageContent = () => {
    getPage(PAGE_ID).then(result => {
      setResourcesHubPage({
        hero: {
            title: result.acf.hero.title,
            image: result.acf.hero.image.url,
            content: DOMPurify.sanitize(result.acf.hero.content)
        },
      })
    })
  }

  const getResources = (page, catId) => {
    getPosts(page, catId ? activeFilter.id : POST_ID, null, 100).then(result => {
      setResources(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.content.rendered,
        excerpt: post.excerpt.rendered,
        slug: post.slug
      })))
    })
  }

  const getFilterCategories = async () => {
      const categories = await getCategoriesByParent(POST_ID)
      const promises = categories.map(async (item) => {
        try {
          const subCategories = await getCategoriesByParent(item.id)
          return subCategories;
        } catch (error) {
          console.error(`Error fetching item ${item}:`, error);
          return null;
        }
      });

      const processedResults = await Promise.all(promises);
      console.log(processedResults)
      setFilterSubcategories(processedResults)
      setFilterCategories(categories)
  }

  useEffect(() => {
    getResourcesHubPageContent()
    getResources(1)
    getFilterCategories()
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if(activeFilter) {
      getResources(1, activeFilter.id)
      const elementRect = latestRef.current.getBoundingClientRect();
       const offset = elementRect.top + window.pageYOffset - 80;

       window.scrollTo({
         top: offset,
         behavior: 'smooth',
       });
    } else {
      getResources(1)
    }

  }, [activeFilter])

  return (
    <div className={'bg-charcoal min-h-[100vh]'}>
      { resourcesHubPage &&
        <Hero
          slides={[{title: resourcesHubPage.hero.title, content: resourcesHubPage.hero.content, image: resourcesHubPage.hero.image}]}
        />
      }{ resourcesHubPage &&
      <div className={'max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 slide-bottom mt-16'}>
      {
        filterCategories && filterCategories.map((filter, i) => (
          <div className={`fade-in`}>
            { i  == 0 && <h2 className={'fade-in md:px-2 pt-4 pb-4 text-white text-md font-secondary'}>Browse our topics</h2>}
            { i !== 0 && <h2 className={'fade-in md:px-2 pt-4 pb-4 text-teal text-md font-secondary font-extrabold hidden md:inline-block'}>&nbsp;</h2>}
            <div className={'w-full flex'}>
              <h2 onClick={() => setActiveFilter(filter)} className={'fade-in cursor-pointer md:px-4 py-1 text-white text-xl mb-2 font-secondary border-magenta bg-magenta border rounded-[100px] inline-block w-full text-center'} dangerouslySetInnerHTML={{__html: filter.name}}></h2>
            </div> 
            <Filter filters={filterSubcategories[i]} handleActiveFilter={setActiveFilter} />
          </div>
        ))
      }

      </div>
      }
      {
      resourcesHubPage &&
      <>
      <h2 ref={latestRef} className={'max-w-screen-lg mx-auto pt-8 pb-4 text-white px-4 md:px-0 border-b border-b-magenta text-center md:text-left text-4xl md:text-4xl mb-12 md:mb-0 font-primary font-extrabold w-full'}>
      <span dangerouslySetInnerHTML={{__html: `${activeFilter ? activeFilter.name : 'All'}`}}></span>
      {activeFilter && <svg  onClick={() => setActiveFilter(null)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer inline-block ml-4 w-8 h-8"><path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>}
      </h2>
      <div className={'max-w-screen-lg mx-auto p-8'}>
          <div className={'grid grid-cols-1 md:grid-cols-2 min-h-[100vh]'}>
              { resources &&
                resources.map((resource, i) => {
                  return (
                    <div key={i} className={'pb-4'}>
                      <Card cta={"Read More"} title={resource.title} content={resource.excerpt} link={resource.slug}/>
                    </div>
                  )
                })
              }
          </div>
      </div>
      </>
    }
    </div>
  );
}

export default ResourceHub;
