import React from 'react'


export const Pagination = ({activePage, handleActivePage, pages}) => {
  const [pagesArr, setPagesArr] = React.useState(null)
  React.useEffect(() => {
    let arr = []
    for(let i = 0; i < pages; i++) {
      console.log('ran ' + i)
      arr.push(i)
    };
    setPagesArr(arr)
  }, [pages])

  const handlePageClick = (pg) => {
    // window.scrollTo({
    //   top: 480,
    //   behavior: 'smooth',
    // });
    handleActivePage(pg)
  }

  return (
    <div className={'flex md:px-0 px-4 gap-2 float-right'}>
    {
      pagesArr && pagesArr.map((pg, i) => (
        <>
        <button onClick={() => handlePageClick(i + 1)} className={`${activePage == i + 1 ? 'bg-coral' : 'border border-coral'} text-white w-[36px] h-[36px] rounded-md text-center py-1`}>{i + 1}</button>
        </>
      ))
    }
    </div>
  )
}

export default Pagination
