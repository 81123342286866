import React from 'react';
import './Treble.css'
import TrebleItem from './TrebleItem'


export const Treble = ({content}) => {
  return (
    <>
    <div className={'w-full bg-charcoal fade-in'}>
      <div className={'treble max-w-screen-lg mx-auto md:items-stretch md:flex md:my-16 shadow-md'}>
      {
        content.map((item, i) => (
          <div className={` w-full flex items-center justify-center lg:flex-start  ${i % 2 == 1 ? 'border border-x-transparent md:border-teal border-t-teal-mid border-b-teal-mid' : 'border border-teal-mid'}  ${i == 0 ? 'border-x-transparent md:border-r-transparent md:border-l-teal-mid' : ''} ${i == 2 ? 'border-x-transparent md:border-l-transparent md:border-r-teal-mid' : ''}`}>
            <TrebleItem key={i} content={item} />
          </div>
        ))
      }
      </div>
    </div>
    </>
  )
}

export default Treble;
