import React, {useState, useEffect, useRef} from 'react';
import './Navigation.css'
import { Link, useLocation, useNavigate }from 'react-router-dom';
import { Image } from '../core';
import NavigationIcon from './NavigationIcon';
import logo from '../../assets/images/logo.png';

export const Navigation = ({handleRouteTransition}) => {

  const location = useLocation()
  const navigate = useNavigate()
  const [sidebarActive, setSidebarActive] = useState(false)
  const [navbarBackgroundActive, setNavbarBackgroundActive] = useState(false)
  const handleScroll = e => setNavbarBackgroundActive(window.scrollY > 0)

  useEffect(() => { window.addEventListener('scroll', handleScroll) }, []);

  const triggerNavigationTransition = (route) => {
    setSidebarActive(false)
    handleRouteTransition(true)
    setTimeout(function () {
      handleRouteTransition(false)
      navigate(route)
    }, 500);
  }

  return (
    <>
    <div className={'relative h-[100px]'}>
    </div>
    <div className={`lcl-navbar z-[100] w-full pb-2 top-0 pt-2 sm:py-2 slide-top fixed transition duration-1000 shadow-sm bg-charcoal/75`}>
      <nav className={'max-w-screen-lg mx-auto flex justify-between fade-in pl-4 pr-4 lg:pl-0 lg:pr-0'}>
        <div onClick={() => triggerNavigationTransition('/')} className={'cursor-pointer flex justify-between w-[160px] pt-1 pb-2 lg:pt-2'}>
          <Image src={logo} />
        </div>
        <div onClick={() => setSidebarActive(true)} className={'sm:hidden'}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff" className="w-12 h-12">
            <path  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
        <ul className={'hidden sm:flex lg:w-4/6 justify-between text-white font-secondary font-extrabold'}>
          <li onClick={() => triggerNavigationTransition('/resource-hub')} className={`cursor-pointer py-4 pl-4 pr-4 transition duration-500 ${location.pathname == '/resource-hub' ? 'bg-teal-mid' : ''}`}>Resources</li>
          <li onClick={() => triggerNavigationTransition('/events')} className={`p-4 transition duration-500 ${location.pathname == '/events' ? 'bg-teal-mid' : ''}`}><Link to={'/events'}>Events</Link></li>
          <li onClick={() => triggerNavigationTransition('/podcasts')} className={`p-4 transition duration-500 ${location.pathname == '/podcasts' ? 'bg-teal-mid' : ''}`}><Link to={'/podcasts'}>Podcast</Link></li>
          <li onClick={() => triggerNavigationTransition('/about')} className={`p-4 transition duration-500 ${location.pathname == '/about' ? 'bg-teal-mid' : ''}`}><Link to={'/about'}>About</Link></li>
          <li className={`p-4 transition duration-500`}><a href={'#sign-up-form'}>Join</a></li>
          <li className={'flex justify-center gap-4 mt-[12px] ml-4'}>
            <a href="https://www.linkedin.com/company/67940782/" target="_blank"><NavigationIcon type={'linkedin'}/></a>
            <a href="https://www.facebook.com/letschatlaw" target="_blank"><NavigationIcon type={'facebook'}/></a>
            <a href="https://tiktok.com/@letschatlaw" target="_blank"><NavigationIcon type={'tiktok'}/></a>
            <a href="https://open.spotify.com/show/45mXNhjaWOgsGvUP7uHcwS" target="_blank"><NavigationIcon type={'spotify'}/></a>
            <a href="https://www.instagram.com/letschatlaw" target="_blank"><NavigationIcon type={'instagram'}/></a>
          </li>
        </ul>
      </nav>
      <div className={`bg-teal w-[180px] h-[100vh] top-0 transition duration-300 ease-in-out z-50 fixed left-[100vw] ${sidebarActive ? '-translate-x-[180px]' : 'translate-x-[180px]'}`}>
      <svg onClick={() => setSidebarActive(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 float-right mt-4 mb-4 mr-32">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
        <ul className={'text-white font-secondary'}>
        <li onClick={() => triggerNavigationTransition('/')} className={'px-4 py-2'}>Home</li>
          <li onClick={() => triggerNavigationTransition('/resource-hub')} className={'px-4 py-2'}>Resources</li>
          <li onClick={() => triggerNavigationTransition('/events')} className={'px-4 py-2'}>Events</li>
          <li onClick={() => triggerNavigationTransition('/podcasts')} className={'px-4 py-2'}>Podcasts</li>
          <li onClick={() => triggerNavigationTransition('/about')} className={'px-4 py-2'}>About</li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default Navigation;
