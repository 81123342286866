import React, {useState, useEffect} from 'react';
import {getPage} from '../api/api'
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";



export const CookiePolicy = () => {
  const [cookiePolicy, setCookiePolicy] = useState(null)

  const getCookiePolicyContent = () => {
    getPage(648).then(result => {
      console.log(result)
      setCookiePolicy({
        title: result.title.rendered,
        content: result.content.rendered
      })
    })
  }

  useEffect(() => {
    getCookiePolicyContent()
  }, [])

  const resetCookieConsent = () => {
    resetCookieConsentValue();
    alert('Your cookie consent was withdrawn')
  }

  return (
    <>
      {
        cookiePolicy &&
        <div className={'max-w-screen-lg mx-auto text-white font-secondary min-h-[100vh] p-8'}>
          <h1 className={'text-xl pb-4'}>Cookie Policy</h1>
          <p>
            You can choose which analytical, functionality and targeting cookies we can set by clicking “Accept” or "Reject" in the cookie banner. Subsequently, you can manage your cookie preferences by clicking <span onClick={resetCookieConsent} className={'font-bold underline'}>here</span>.
          </p>
          <p dangerouslySetInnerHTML={{__html: cookiePolicy.content}}></p>
        </div>
      }
    </>
  )
}

export default CookiePolicy
